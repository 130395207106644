import { NotificationManager } from "react-notifications";
import { API } from "../../core/api";
import {
  GET_DALLE_MODALS,
  GET_THEMES,
  GET_LOCALES,
  GET_GPT_MODEL,
  GET_WHITE_PAGES,
} from "./types";
import {helperError} from "../../helpers/helperError";
import axios from "axios";


export const changeStatusWhitePage = (whitePage, status) => async () => {
  try {
    await API.put(`microservices/white-page-generator/api/white-pages/${whitePage}/${status}`);
  } catch (e) {
    helperError(e);
  }
};

export const getThemes = () => async (dispatch) => {
  try {
    const response = await API.get("microservices/white-page-generator/api/directories/themes");
    dispatch({ type: GET_THEMES, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};

export const getWhitePages = (page,id,status) => async (dispatch) => {
  try {
    const response = await API.get(`microservices/white-page-generator/api/white-pages?page=${page}&filter[user_id]=${id}&filter[status]=${status}`);
    dispatch({ type: GET_WHITE_PAGES, payload: response.data });
    return response.data.data;
  } catch (e) {
    helperError(e);
  }
};

export const getRegenerateText = (options, whitePage) => async () => {
  try {
    await API.put(
      `microservices/white-page-generator/api/white-pages/${whitePage}/regenerate-json-structure`,
      options
    );
  } catch (e) {
    helperError(e);
  }
};

export const getRegenerateImg = (options, whitePage) => async () => {
  try {
    await API.put(
      `microservices/white-page-generator/api/white-pages/${whitePage}/regenerate-images-structure`,
      options
    );
  } catch (e) {
    helperError(e);
  }
};


export const getPromptText = (options) => async () => {
  try {
    const resp = await axios.post(
        `http://95.216.4.23:8443/api/white-pages/generate-text-prompt-by-brief-data`,
        options
    );
    return resp
  } catch (e) {
    helperError(e);
  }
};

export const getPromptImage = (options) => async () => {
  try {
    const resp = await axios.post(
        `http://95.216.4.23:8443/api/white-pages/generate-image-prompt-by-brief-data`,
        options
    );
    return resp
  } catch (e) {
    helperError(e);
  }
};

export const getPromptLogo = (options) => async () => {
  try {
    const resp = await axios.post(
        `http://95.216.4.23:8443/api/white-pages/generate-logo-prompt-by-brief-data`,
        options
    );
    return resp
  } catch (e) {
    helperError(e);
  }
};

export const getPromptFavicon = (options) => async () => {
  try {
    const resp = await axios.post(
        `http://95.216.4.23:8443/api/white-pages/generate-favicon-prompt-by-brief-data`,
        options
    );
    return resp
  } catch (e) {
    helperError(e);
  }
};

export const getWhitePage = (id) => async () => {
  try {
    const response = await API.get(`microservices/white-page-generator/api/white-pages/${id}`);
    return response.data;
  } catch (e) {
    helperError(e);
  }
};

export const getWhitePagePreview = (id) => async () => {
  try {
    const response = await API.get(`microservices/white-page-generator/api/white-pages/${id}/preview`);
    return response.data;
  } catch (e) {
    // NotificationManager.error(`${e.message}`, "", 3500);
    console.log(e)
  }
};

export const createWhitePage = (options) => async () => {
  try {
    const response = await API.post("microservices/white-page-generator/api/white-pages", options);
    NotificationManager.success(`Генерация началась`, "", 3500);
    return response.data;
  } catch (e) {
    helperError(e);
  }
};

export const generatePrompt = (options) => async () => {
  try {
    const response = await API.post(`microservices/white-page-generator/api/white-pages/generate-prompt`, options);
    NotificationManager.success(`Промт успешно сгенерирован`, "", 3500);
    return response;
  } catch (e) {
    helperError(e);
  }
};

export const downloadWhitePage = (whitePage) => async () => {
  try {
    const response = await API.get(`microservices/white-page-generator/api/white-pages/${whitePage}/download`);
    return response;
  } catch (e) {
    helperError(e);
  }
};

export const regenerateWhitePage = (whitePage, options) => async () => {
  try {
    await API.put(`microservices/white-page-generator/api/white-pages/${whitePage}/regenerate`, options);
    NotificationManager.success(`Генерация началась`, "", 3500);
  } catch (e) {
    helperError(e);
  }
};

export const getLocales = () => async (dispatch) => {
  try {
    const response = await API.get("microservices/white-page-generator/api/directories/locales");
    dispatch({ type: GET_LOCALES, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};

export const getGptModel = () => async (dispatch) => {
  try {
    const response = await API.get("microservices/white-page-generator/api/directories/text-generation-models");
    dispatch({ type: GET_GPT_MODEL, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};

export const getDalleModels = () => async (dispatch) => {
  try {
    const response = await API.get("microservices/white-page-generator/api/directories/image-generation-models");
    dispatch({ type: GET_DALLE_MODALS, payload: response.data });
  } catch (e) {
    helperError(e);
  }
};



export const getHeaderColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `microservices/white-page-generator/api/directories/header-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};

export const getFooterColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `microservices/white-page-generator/api/directories/footer-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};

export const getBtnColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `microservices/white-page-generator/api/directories/btn-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};


export const getOtherColors = (theme) => async () => {
  try {
    const resp = await API.get(
        `microservices/white-page-generator/api/directories/other-elements-color-themes/${theme}`,
    );
    return resp.data.map((item) => ({label: item, value: item}))
  } catch (e) {
    helperError(e);
  }
};
