import {Button, Col, Form, Row, Spinner} from "react-bootstrap";
import { useEffect, useState } from "react";
import SelectSearch from "../UI/Select";
import { optionsTypePage } from "../../helpers/options";
import { useDispatch, useSelector } from "react-redux";
import SelectSearchMulti from "../UI/SelectIsMulti";
import {
  createWhitePage,
  getBtnColors,
  getFooterColors,
  getHeaderColors, getOtherColors, getPromptFavicon, getPromptImage, getPromptLogo, getPromptText, regenerateWhitePage
} from "../../pages/WhitePage/action";
import "./styles.scss";
import UIFormTextaria from "../UI/UIFormTextaria";
import UIFormInput from "../UI/UIFormInput";

const GeneratorWhitePage = ({edit, closeModal}) => {
  const themes = useSelector((state) => state.whitePageReducer.themes);
  const dalle = useSelector((state) => state.whitePageReducer.dalle);
  const locales = useSelector((state) => state.whitePageReducer.locales);
  const gptModels = useSelector((state) => state.whitePageReducer.gptModels);

  const [headersColors, setHeadersColors] = useState([]);
  const [footerColors, setFooterColors] = useState([]);
  const [btnColors, setBtnColors] = useState([]);
  const [otherColors, setOtherColors] = useState([]);
  const [isLoadingImagePrompt, setIsLoadingImagePrompt] = useState(false);
  const [isLoadingLogoPrompt, setIsLoadingLogoPrompt] = useState(false);
  const [isLoadingFaviconPrompt, setIsLoadingFaviconPrompt] = useState(false);

  const [commonData, setCommonData] = useState({
    iframe_src: "",
    currency_label: '',
    goods_quantity: 3,
    posts_quantity: 3
  })


  const [companyMainData, setCompanyMainData] = useState({
    title: '',
    company_activity_scope: [],
    geo: [],
    locales: [],
    goals: [],
    unique_selling_proposition: [],
  })

  const [targetAudience, setTargetAudience] = useState({
    age: '',
    interest: [],
    requirements: [],
  })


  const [siteStructure, setSiteStructure] = useState({
    main_page: '',
    services_page: '',
    contacts_page: '',
    reviews_page: ''
  })


  const [adsContentData, setAdsContentData] = useState({
    titles: [],
    descriptions: [],
    keywords: [],
    stop_words: []
  })


  const isValidData = () => {
    const isCompanyMainDataValid =
        companyMainData.title.trim() &&
        companyMainData.company_activity_scope.length > 0 &&
        companyMainData.geo.length > 0 &&
        companyMainData.locales.length > 0 &&
        companyMainData.goals.length > 0 &&
        companyMainData.unique_selling_proposition.length > 0;

    const isTargetAudienceValid =
        targetAudience.age.trim() &&
        targetAudience.interest.length > 0 &&
        targetAudience.requirements.length > 0;

    const isSiteStructureValid =
        siteStructure.main_page.trim() &&
        siteStructure.services_page.trim() &&
        siteStructure.contacts_page.trim() &&
        siteStructure.reviews_page.trim();

    const isAdsContentDataValid =
        adsContentData.titles.length > 0 &&
        adsContentData.descriptions.length > 0 &&
        adsContentData.keywords.length > 0 &&
        adsContentData.stop_words.length > 0;

    return (
        isCompanyMainDataValid &&
        isTargetAudienceValid &&
        isSiteStructureValid &&
        isAdsContentDataValid
    );
  };


  const [build, setBuild] = useState({
    name: "",
    theme: null,
    topic: "",
    domain_name: "",
    site_type: null,
    locale: null,
    available_locales: [],
    chat_gpt_model: null,
    dalle_model: "dall-e-3",
    use_ai: true,
    text_prompt: '',
    image_prompt: '',
    favicon_prompt: '',
    logo_prompt: "",
    tag_id: "",
    has_shop: false,
    has_blog: false,
    has_services: false,
    has_iframe: false,
    has_terms_of_use: false,
    has_privacy_policy: false,
    has_return_policy: false,
    has_delivery_policy: false,
    theme_color: {
      header: null,
      footer: null,
      first_btn: null,
      second_btn: null,
      other_elements: null,
    },
    contacts_information: {
      telegram: "",
      instagram: '',
      facebook: '',
      tiktok: '',
      youtube: "",
      linkedin: "",
      x: "",
      iframe_src: '',
      addresses: {},
    },
  });

  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPrompt, setIsLoadingPrompt] = useState(false);

  const dispatch = useDispatch();


  const handleChangeInput = (e) => {
    const value = e.target.value;
    const key = e.target.id;

    if (key === 'domain_name') {

        const nameFor = value.split('.')[0];

        setBuild((prevBuild) => ({
          ...prevBuild,
          domain_name: value, // Оновлюємо домен.
          contacts_information: {
            ...prevBuild.contacts_information,
            telegram: `https://www.telegram.com/${nameFor}`,
            instagram:  `https://www.instagram.com/${nameFor}`,
            facebook:  `https://www.facebook.com/${nameFor}`,
            tiktok:  `https://www.tiktok.com/@${nameFor}`,
            youtube:  `https://www.youtube.com/${nameFor}`,
            linkedin:  `https://www.linkedin.com/in/${nameFor}`,
            x:  `https://twitter.com/${nameFor}`,
          },
        }));
    } else {
      setBuild((prevBuild) => ({
        ...prevBuild,
        [key]: value,
      }));
    }
  };



  const handleChangeInputContactInfo = (e) => {
    const value = e.target.value;
    const key = e.target.id;
    setBuild({ ...build, contacts_information: {...build.contacts_information, [key]: value} });
  };


  const handleChangeInputCompanyMainData = (e) => {
    const value = e.target.value;
    const key = e.target.id;
    setCompanyMainData({ ...companyMainData, [key]: value });
  };

  const handleChangeInputTargetAudience = (e) => {
    const value = e.target.value;
    const key = e.target.id;
    setTargetAudience({ ...targetAudience, [key]: value });
  };

  const handleChangeInputSiteStructure = (e) => {
    const value = e.target.value;
    const key = e.target.id;
    setSiteStructure({ ...siteStructure, [key]: value });
  };


  const handleChangeInputCommonData = (e) => {
    const value = e.target.value;
    const key = e.target.id;
    setCommonData({ ...commonData, [key]: value });
  };

  const handleChangeInputAddress = (e) => {
    const value = e.target.value;
    const key = e.target.id;

    setBuild({
      ...build,
      contacts_information: {
        ...build.contacts_information,
        addresses: { ...build.contacts_information.addresses, [key]: value },
      },
    });
  };

  const handleChangeSelect = (item, id) => {
    if (id === "locale") {
      setBuild({ ...build, available_locales: [item], [id]: item });
      return;
    }
    setBuild({ ...build, [id]: item });
  };

  const handleChangeSelectCompanyMainData = (item, id) => {
    setCompanyMainData({ ...companyMainData, [id]: item });
  };


  const handleChangeSelectColors = (item, id) => {
    setBuild({ ...build, theme_color: {...build.theme_color, [id]: item} });
  };

  const randomizer = async () => {
    if (themes.length > 0) {
      const randomIndex = Math.floor(Math.random() * themes.length);
      const randomTheme = themes[randomIndex];
      await setBuild({ ...build, theme: randomTheme.value });
    }

  };

  useEffect(() => {
    if(!edit) {
      randomizer();
    }
    if (edit) {
      const builded = {
        name: edit?.name,
        theme: edit?.theme,
        topic: edit?.topic,
        domain_name: edit?.domain_name,
        site_type: edit?.site_type,
        locale: edit?.locale,
        available_locales: edit?.available_locales,
        chat_gpt_model: null,
        dalle_model: "dall-e-3",
        use_ai: true,
        tag_id: edit?.tag_id,
        text_prompt: edit?.text_prompt,
        image_prompt: edit?.image_prompt,
        has_shop: edit?.has_shop,
        has_blog: edit?.has_blog,
        has_services: edit?.has_services,
        has_iframe: edit?.has_iframe,
        favicon_prompt: edit?.favicon_prompt,
        logo_prompt: edit?.logo_prompt,
        has_terms_of_use: edit?.has_terms_of_use,
        has_privacy_policy: edit?.has_privacy_policy,
        has_return_policy: edit?.has_return_policy,
        has_delivery_policy: edit?.has_delivery_policy,
        theme_color: {
          header: edit.theme_color.header,
          footer: edit?.theme_color?.footer,
          first_btn: edit?.theme_color?.first_btn,
          second_btn: edit?.theme_color?.second_btn,
          other_elements: edit?.theme_color?.other_elements,
        },
        contacts_information: {
          telegram: edit?.contacts_information?.telegram,
          instagram: edit?.contacts_information?.instagram,
          facebook: edit?.contacts_information?.facebook,
          tiktok: edit?.contacts_information?.tiktok,
          youtube: edit?.contacts_information?.youtube,
          linkedin: edit?.contacts_information?.linkedin,
          x: edit?.contacts_information?.x,
          iframe_src: edit?.contacts_information?.iframe_src,
          addresses:  edit?.contacts_information?.addresses
        },
      };
      setCommonData(edit?.common_data);


      if(edit?.brief_data) {
        setCompanyMainData({
          company_activity_scope: edit?.brief_data?.companyMainData?.companyActivityScope,
          geo: edit?.brief_data?.companyMainData?.geo,
          goals: edit?.brief_data?.companyMainData?.goals,
          locales: edit?.brief_data?.companyMainData?.locales,
          title: edit?.brief_data?.companyMainData?.title,
          unique_selling_proposition: edit?.brief_data?.companyMainData.uniqueSellingProposition,
        });

        setAdsContentData({
          stop_words: edit?.brief_data?.adsContentData?.stopWords,
          keywords: edit?.brief_data?.adsContentData?.keywords,
          titles: edit?.brief_data?.adsContentData?.titles,
          descriptions: edit?.brief_data?.adsContentData?.descriptions,
        });
        setSiteStructure({
          services_page: edit?.brief_data?.siteStructure?.servicesPage,
          contacts_page: edit?.brief_data?.siteStructure?.contactsPage,
          reviews_page:  edit?.brief_data?.siteStructure?.reviewsPage,
          main_page: edit?.brief_data?.siteStructure?.mainPage
        });
        setTargetAudience(edit?.brief_data?.targetAudience)
      }

      setBuild({...build, ...builded});
    }
  }, [themes]);


  const loadDataColors = async () => {
    if(build.theme !== null) {
      if(!edit) {
        setBuild(prevBuild => ({
          ...prevBuild,
          theme_color: {
            header: null,
            footer: null,
            first_btn: null,
            second_btn: null,
            other_elements: null,
          }
        }));
      }

      const header = await dispatch(getHeaderColors(build.theme));
      const btn = await dispatch(getBtnColors(build.theme));
      const footer = await dispatch(getFooterColors(build.theme));
      const other = await dispatch(getOtherColors(build.theme));

      if(!edit) {

        if (header) {
          const randomIndex = Math.floor(Math.random() * header.length);
          const randomTheme = header[randomIndex];
          if(randomTheme?.value) {
            setBuild(prevBuild => ({
              ...prevBuild,
              theme_color: {
                ...prevBuild.theme_color,
                header: randomTheme?.value,
              },
            }));
          }
        }

        if (footer) {
          const randomIndex = Math.floor(Math.random() * footer.length);
          const randomTheme = footer[randomIndex];
          if(randomTheme?.value) {
            setBuild(prevBuild => ({
              ...prevBuild,
              theme_color: {
                ...prevBuild.theme_color,
                footer: randomTheme.value,
              },
            }));
          }
        }

        if (btn) {
          const randomIndex = Math.floor(Math.random() * btn.length);
          const randomTheme = btn[randomIndex];
          if(randomTheme?.value) {
            setBuild(prevBuild => ({
              ...prevBuild,
              theme_color: {
                ...prevBuild.theme_color,
                first_btn: randomTheme.value,
              },
            }));
          }
        }

        if (btn) {
          const randomIndex = Math.floor(Math.random() * btn.length);
          const randomTheme = btn[randomIndex];
          if(randomTheme?.value) {
            setBuild(prevBuild => ({
              ...prevBuild,
              theme_color: {
                ...prevBuild.theme_color,
                second_btn: randomTheme.value,
              },
            }));
          }
        }

        if (other) {
          const randomIndex = Math.floor(Math.random() * other.length);
          const randomTheme = other[randomIndex];
          if(randomTheme?.value) {
            setBuild(prevBuild => ({
              ...prevBuild,
              theme_color: {
                ...prevBuild.theme_color,
                other_elements: randomTheme.value,
              },
            }));
          }
        }

      }

      setHeadersColors(header);
      setBtnColors(btn);
      setFooterColors(footer);
      setOtherColors(other);
    }
  };



  useEffect(() => {
    loadDataColors();
  }, [build.theme]);

  const handleClickButton = async () => {

    const { contacts_information,chat_gpt_model, dalle_model,...remainOptions } = build;

    let options = {
      ...remainOptions,
      contacts_information: {
        addresses: contacts_information.addresses,
        iframe_src: contacts_information.iframe_src,
      },
      brief: {
        company_main_data: companyMainData,
        target_audience: targetAudience,
        site_structure: siteStructure,
        ads_content_data: adsContentData
      },
      image_generation_model: dalle_model,
      text_generation_model: chat_gpt_model
    }

    if(build?.has_iframe) {
      options.common_data = {...options.common_data, iframe_src: commonData.iframe_src}
    }

    if(build?.has_shop) {
      options.common_data = {...options.common_data, currency_label: commonData.currency_label, goods_quantity: commonData.goods_quantity}
    }

    if(build?.has_blog) {
      options.common_data = {...options.common_data, posts_quantity: commonData.posts_quantity}
    }

    if(contacts_information.telegram.length !== 0 ) {
      options.contacts_information['telegram'] = contacts_information.telegram;
    }
    if(contacts_information.instagram.length !== 0 ) {
      options.contacts_information['instagram'] = contacts_information.instagram;
    }
    if(contacts_information.tiktok.length !== 0 ) {
      options.contacts_information['tiktok'] = contacts_information.tiktok;
    }
    if(contacts_information.x.length !== 0 ) {
      options.contacts_information['x'] = contacts_information.x;
    }
    if(contacts_information.facebook.length !== 0 ) {
      options.contacts_information['facebook'] = contacts_information.facebook;
    }
    if(contacts_information.linkedin.length !== 0 ) {
      options.contacts_information['linkedin'] = contacts_information.linkedin;
    }
    if(contacts_information.youtube.length !== 0 ) {
      options.contacts_information['youtube'] = contacts_information.youtube;
    }


    if(edit) {
      setIsLoading(true);
      await dispatch(regenerateWhitePage (edit.id, options));

      setIsLoading(false);
      setCommonData({
        iframe_src: "",
        currency_label: '',
        goods_quantity: 3,
        posts_quantity: 3
      })

      setBuild({
        name: "",
        theme: null,
        topic: "",
        domain_name: "",
        site_type: null,
        locale: null,
        available_locales: [],
        chat_gpt_model: null,
        dalle_model: "dall-e-3",
        use_ai: true,
        has_shop: false,
        has_blog: false,
        has_services: false,
        has_iframe: false,
        theme_color: {
          header: null,
          footer: null,
          first_btn: null,
          second_btn: null,
          other_elements: null,
        },
        contacts_information: {
          telegram: "",
          instagram: '',
          facebook: '',
          tiktok: '',
          youtube: "",
          linkedin: "",
          x: "",
          iframe_src: "",
          addresses: {},
        },
      });
      closeModal()
      return;
    }

    setIsLoading(true);
    const data = await dispatch(createWhitePage(options));
    if (data) {
      setCommonData({
        iframe_src: "",
        currency_label: '',
        goods_quantity: 3,
        posts_quantity: 3
      })
      setBuild({
        name: "",
        theme: null,
        topic: "",
        domain_name: "",
        site_type: null,
        locale: null,
        available_locales: [],
        chat_gpt_model: null,
        dalle_model: "dall-e-3",
        use_ai: true,
        has_shop: false,
        has_blog: false,
        has_services: false,
        has_iframe: false,
        has_terms_of_use: false,
        has_privacy_policy: false,
        has_return_policy: false,
        has_delivery_policy: false,
        theme_color: {
          header: null,
          footer: null,
          first_btn: null,
          second_btn: null,
          other_elements: null,
        },
        contacts_information: {
          telegram: "",
          instagram: '',
          facebook: '',
          tiktok: '',
          youtube: "",
          linkedin: "",
          x: "",
          iframe_src: '',
          addresses: {
            uk_UA: "",
            en_US: "",
            ru_RU: "",
          },
        },
      });
    }
    setIsLoading(false);
  };

  const handleClickGeneratePromt = async () => {
    setIsLoadingPrompt(true);
    const options = {
      text_generation_model: build.chat_gpt_model,
      company_main_data: companyMainData,
      target_audience: targetAudience,
      site_structure: siteStructure,
      ads_content_data: adsContentData
    };
    const response = await dispatch(getPromptText(options));

    if (response?.data?.text_prompt) {
      setBuild({ ...build, text_prompt: response?.data?.text_prompt });
    }

    setIsLoadingPrompt(false);
  };

  const handleClickGenerateImagePrompt = async () => {
    setIsLoadingImagePrompt(true);
    const options = {
      text_generation_model: build.chat_gpt_model,
      company_main_data: companyMainData,
      target_audience: targetAudience,
      site_structure: siteStructure,
      ads_content_data: adsContentData
    };
    const response = await dispatch(getPromptImage(options));

    if (response?.data?.image_prompt) {
      setBuild({ ...build, image_prompt: response?.data?.image_prompt });
    }

    setIsLoadingImagePrompt(false);
  };

  const handleClickGenerateLogoPrompt = async () => {
    setIsLoadingLogoPrompt(true);
    const options = {
      text_generation_model: build.chat_gpt_model,
      company_main_data: companyMainData,
      target_audience: targetAudience,
      site_structure: siteStructure,
      ads_content_data: adsContentData
    };
    const response = await dispatch(getPromptLogo(options));

    if (response?.data?.logo_prompt) {
      setBuild({ ...build, logo_prompt: response?.data?.logo_prompt });
    }

    setIsLoadingLogoPrompt(false);
  };


  const handleClickGenerateFaviconPrompt = async () => {
    setIsLoadingFaviconPrompt(true);
    const options = {
      text_generation_model: build.chat_gpt_model,
      company_main_data: companyMainData,
      target_audience: targetAudience,
      site_structure: siteStructure,
      ads_content_data: adsContentData
    };
    const response = await dispatch(getPromptFavicon(options));

    if (response?.data?.favicon_prompt) {
      setBuild({ ...build, favicon_prompt: response?.data?.favicon_prompt });
    }

    setIsLoadingFaviconPrompt(false);
  };

  const isDisabledButton = () => {
    if (build?.name?.length === 0) return true;
    else if (build?.domain_name?.length === 0) return true;
    else if (build?.locale === null) return true;
    else if (build?.chat_gpt_model === null) return true;
    else if (build?.dalle_model === null) return true;
    else if (build?.theme === null) return true;
    else if (build?.site_type === null) return true;
    else if (build?.text_prompt.length === 0) return true;
    else if (build?.image_prompt.length === 0) return true;
    else if (build?.logo_prompt.length === 0) return true;
    else if (build?.favicon_prompt.length === 0) return true;
    else if (build?.available_locales.length === 0) return true;
    // else if (build?.theme_color.footer === null) return true;
    // else if (build?.theme_color.first_btn === null) return true;
    // else if (build?.theme_color.second_btn === null) return true;
    // else if (build?.theme_color.header === null) return true;
    // else if (build?.theme_color.other_elements === null) return true;
    else if (build?.has_iframe && commonData?.iframe_src?.length === 0) return true;
    else if (build?.has_shop && commonData?.currency_label?.length === 0) return true;
    else if (build?.has_shop && commonData?.goods_quantity?.length === 0) return true;
    else if (build?.has_blog && commonData?.posts_quantity?.length === 0) return true;
    return false;
  };

  const isDisabledButtonPrompt = () => {
    if (build.chat_gpt_model === null) return true;
    return false;
  };

  return (
    <div className="p-4 w-100 pb-0">
      <Row>
        <Col xs={4}>
          <UIFormInput
            onChange={handleChangeInput}
            type="text"
            id="name"
            label={"Название архива"}
            value={build?.name ? build?.name : ""}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "theme")}
            title={"Тема"}
            options={themes}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.theme}
            value={build?.theme}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "site_type")}
            title={"Тип страницы"}
            options={optionsTypePage}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.site_type}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <UIFormInput
            onChange={handleChangeInput}
            type="text"
            id="tag_id"
            label={"Google Ads Tag ID"}
            value={build?.tag_id ? build?.tag_id : ""}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "locale")}
            title={"Основной язык"}
            options={locales}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.locale}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "chat_gpt_model")}
            title={"Версия чата GPT"}
            options={gptModels}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.chat_gpt_model}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <UIFormInput
            onChange={handleChangeInput}
            type="text"
            id="domain_name"
            label={"Название домена"}
            value={build?.domain_name ? build?.domain_name : ""}
          />
        </Col>
        <Col xs={4}>
          <SelectSearchMulti
            onChange={(e) => handleChangeSelect(e, "available_locales")}
            title={"Доступные языки вайта"}
            options={locales}
            itemLabel={"label"}
            itemValue={"value"}
            values={build?.available_locales}
            default={build?.available_locales}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
            onChange={(e) => handleChangeSelect(e, "dalle_model")}
            title={"Версия Dall-e"}
            options={dalle}
            itemLabel={"label"}
            itemValue={"value"}
            default={build?.dalle_model}
          />
        </Col>
      </Row>
      {build?.available_locales?.length !== 0 && (
        <Row>

          {build?.available_locales.map((item) => (
              <Col xs={4}>
                <UIFormInput
                    onChange={handleChangeInputAddress}
                    type="text"
                    id={item}
                    label={`Адрес ${item}`}
                    value={
                      build?.contacts_information?.addresses[item]
                          ? build?.contacts_information?.addresses[item]
                          : ""
                    }
                />
              </Col>

          ))}
        </Row>
      )}
      <Row>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "header")}
              title={"Header Color"}
              options={headersColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.header}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "first_btn")}
              title={"First Btn Color"}
              options={btnColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.first_btn}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "second_btn")}
              title={"Second Btn Color"}
              options={btnColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.second_btn}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "footer")}
              title={"Footer Color"}
              options={footerColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.footer}
          />
        </Col>
        <Col xs={4}>
          <SelectSearch
              onChange={(e) => handleChangeSelectColors(e, "other_elements")}
              title={"Other Element"}
              options={otherColors}
              itemLabel={"label"}
              itemValue={"value"}
              default={build?.theme_color.other_elements}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="telegram"
              label={"Telegram"}
              value={build?.contacts_information.telegram ? build?.contacts_information.telegram : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="instagram"
              label={"Instagram"}
              value={build?.contacts_information.instagram ? build?.contacts_information.instagram : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="facebook"
              label={"Facebook"}
              value={build?.contacts_information.facebook ? build?.contacts_information.facebook : ""}
          />
        </Col>
      </Row>
      <Row>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="tiktok"
              label={"Tiktok"}
              value={build?.contacts_information.tiktok ? build?.contacts_information.tiktok : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="youtube"
              label={"Youtube"}
              value={build?.contacts_information.youtube ? build?.contacts_information.youtube : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="linkedin"
              label={"Linkedin"}
              value={build?.contacts_information.linkedin ? build?.contacts_information.linkedin : ""}
          />
        </Col>
      </Row>

      <Row>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="iframe_src"
              label={"Iframe src"}
              value={build?.contacts_information.iframe_src ? build?.contacts_information.iframe_src : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputContactInfo}
              type="text"
              id="x"
              label={"X ( old twitter )"}
              value={build?.contacts_information.x ? build?.contacts_information.x : ""}
          />
        </Col>
      </Row>

      <div className='d-flex gap-3 align-items-center mt-2'>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_shop: !build.has_shop})}
              id="has_shop"
              label="Has shop"
              className="ml-1"
              checked={build?.has_shop ? build?.has_shop : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_blog: !build.has_blog})}
              id="has_blog"
              label="Has blog"
              className="ml-1"
              checked={build?.has_blog ? build?.has_blog : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_services: !build.has_services})}
              id="has_services"
              label="Has services"
              className="ml-1"
              checked={build?.has_services ? build?.has_services : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_iframe: !build.has_iframe})}
              id="has_iframe"
              label="Has iframe"
              className="ml-1"
              checked={build?.has_iframe ? build?.has_iframe : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_terms_of_use: !build.has_terms_of_use})}
              id="has_terms_of_use"
              label="Has terms of use"
              className="ml-1"
              checked={build?.has_terms_of_use ? build?.has_terms_of_use : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_privacy_policy: !build.has_privacy_policy})}
              id="has_privacy_policy"
              label="Has privacy policy"
              className="ml-1"
              checked={build?.has_privacy_policy ? build?.has_privacy_policy : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_return_policy: !build.has_return_policy})}
              id="has_return_policy"
              label="Has return policy"
              className="ml-1"
              checked={build?.has_return_policy ? build?.has_return_policy : false}
          />
        </div>
        <div xs={1}>
          <Form.Check
              onChange={() => setBuild({...build, has_delivery_policy: !build.has_delivery_policy})}
              id="has_delivery_policy"
              label="Has delivery policy"
              className="ml-1"
              checked={build?.has_delivery_policy ? build?.has_delivery_policy : false}
          />
        </div>
      </div>
      <Row>
        {build?.has_iframe && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="text"
              id="iframe_src"
              label={"Iframe src"}
              value={commonData?.iframe_src ? commonData?.iframe_src : ""}
          />
        </Col>}
        {build?.has_shop && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="text"
              id="currency_label"
              label={"Currency label"}
              value={commonData?.currency_label ? commonData?.currency_label : ""}
          />
        </Col>}
        {build?.has_shop && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="number"
              max={12}
              id="goods_quantity"
              label={"Goods quantity"}
              min={3}
              value={commonData?.goods_quantity ? commonData?.goods_quantity : 0}
          />
        </Col>}
        {build?.has_blog && <Col xs={3}>
          <UIFormInput
              onChange={handleChangeInputCommonData}
              type="number"
              id="posts_quantity"
              label={"Posts quantity"}
              min={3}
              max={6}
              value={commonData?.posts_quantity ? commonData?.posts_quantity : ""}
          />
        </Col>}
        </Row>

       <Row>
         <span className='title-form'>Общие данные о компании и сайте:</span>
          <Col xs={4}>
            <UIFormInput
                onChange={handleChangeInputCompanyMainData}
                type="text"
                id="title"
                label={"Название"}
                value={companyMainData?.title ? companyMainData?.title : ""}
            />
          </Col>
         <Col xs={4}>
           <SelectSearchMulti
               onChange={(e) => handleChangeSelectCompanyMainData(e, "locales")}
               title={"Язык объявлений"}
               options={locales}
               itemLabel={"label"}
               itemValue={"value"}
               default={companyMainData?.locales}
           />
         </Col>
      </Row>
      <Row>
        <span className='title-form'>Описание страниц и структуры сайта:</span>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputSiteStructure}
              type="text"
              id="main_page"
              label={"Главная страница"}
              value={siteStructure?.main_page ? siteStructure?.main_page : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputSiteStructure}
              type="text"
              id="services_page"
              label={"Страница «Услуги»"}
              value={siteStructure?.services_page ? siteStructure?.services_page : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputSiteStructure}
              type="text"
              id="contacts_page"
              label={"Форма обратной связи"}
              value={siteStructure?.contacts_page ? siteStructure?.contacts_page : ""}
          />
        </Col>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputSiteStructure}
              type="text"
              id="reviews_page"
              label={"Отзывы и кейсы"}
              value={siteStructure?.reviews_page ? siteStructure?.reviews_page : ""}
          />
        </Col>
      </Row>
      <Row>
        <span className='title-form'>Целевая аудитория:</span>
        <Col xs={4}>
          <UIFormInput
              onChange={handleChangeInputTargetAudience}
              type="text"
              id="age"
              label={"Возраст"}
              value={targetAudience?.age ? targetAudience?.age : ""}
          />
        </Col>
      </Row>
      <Row>
        <span className='title-form'>Сфера деятельности:</span>
        {(companyMainData?.company_activity_scope || []).map((item, index) => (
            <Col xs={4} className=''>
              <UIFormInput
                  onChange={({target}) => setCompanyMainData({...companyMainData, company_activity_scope: companyMainData.company_activity_scope.map((el, indexScope) => {

                      if(index === indexScope) {
                        return target.value

                      }

                      return el
                    })})}
                  type="text"
                  className='position-relative'
                  id="title"
                  deleteIcon
                  value={companyMainData?.company_activity_scope[index] ? companyMainData?.company_activity_scope[index] : ""}
              />
              <div className='icon-delete' onClick={() => setCompanyMainData({...companyMainData, company_activity_scope: companyMainData.company_activity_scope.filter((_,indexScope) => indexScope !== index )})}/>
            </Col>
        ))}
      </Row>
      <Row>
          <div className='button-add' onClick={() => setCompanyMainData({...companyMainData, company_activity_scope: [...companyMainData.company_activity_scope, '']})}>
            +
          </div>
      </Row>
      <Row>
        <span className='title-form'>География аудитории:</span>
        {(companyMainData?.geo || []).map((item, index) => (
            <Col xs={4} className=''>
              <UIFormInput
                  onChange={({target}) => setCompanyMainData({...companyMainData, geo: companyMainData.geo.map((el, indexScope) => {

                      if(index === indexScope) {
                        return target.value

                      }

                      return el
                    })})}
                  type="text"
                  className='position-relative'
                  id="title"
                  deleteIcon
                  value={companyMainData?.geo[index] ? companyMainData?.geo[index] : ""}
              />
              <div className='icon-delete' onClick={() => setCompanyMainData({...companyMainData, geo: companyMainData.geo.filter((_,indexScope) => indexScope !== index )})}/>
            </Col>
        ))}
      </Row>
      <Row>
        <div className='button-add' onClick={() => setCompanyMainData({...companyMainData, geo: [...companyMainData.geo, '']})}>
          +
        </div>
      </Row>
      <Row>
        <span className='title-form'>Цели:</span>
        {(companyMainData?.goals || []).map((item, index) => (
            <Col xs={4} className=''>
              <UIFormInput
                  onChange={({target}) => setCompanyMainData({...companyMainData, goals: companyMainData.goals.map((el, indexScope) => {

                      if(index === indexScope) {
                        return target.value

                      }

                      return el
                    })})}
                  type="text"
                  className='position-relative'
                  id="title"
                  deleteIcon
                  value={companyMainData?.goals[index] ? companyMainData?.goals[index] : ""}
              />
              <div className='icon-delete' onClick={() => setCompanyMainData({...companyMainData, goals: companyMainData.goals.filter((_,indexScope) => indexScope !== index )})}/>
            </Col>
        ))}
      </Row>
      <Row>
        <div className='button-add' onClick={() => setCompanyMainData({...companyMainData, goals: [...companyMainData.goals, '']})}>
          +
        </div>
      </Row>
      <Row>
        <span className='title-form'>Уникальное продажное предложение:</span>
        {(companyMainData?.unique_selling_proposition || []).map((item, index) => (
            <Col xs={4} className=''>
              <UIFormInput
                  onChange={({target}) => setCompanyMainData({...companyMainData, unique_selling_proposition: companyMainData.unique_selling_proposition.map((el, indexScope) => {

                      if(index === indexScope) {
                        return target.value

                      }

                      return el
                    })})}
                  type="text"
                  className='position-relative'
                  id="title"
                  deleteIcon
                  value={companyMainData?.unique_selling_proposition[index] ? companyMainData?.unique_selling_proposition[index] : ""}
              />
              <div className='icon-delete' onClick={() => setCompanyMainData({...companyMainData, unique_selling_proposition: companyMainData.unique_selling_proposition.filter((_,indexScope) => indexScope !== index )})}/>
            </Col>
        ))}
      </Row>
      <Row>
        <div className='button-add' onClick={() => setCompanyMainData({...companyMainData, unique_selling_proposition: [...companyMainData.unique_selling_proposition, '']})}>
          +
        </div>
      </Row>
      <Row>
        <span className='title-form'>Интересы:</span>
        {(targetAudience?.interest || []).map((item, index) => (
            <Col xs={4} className=''>
              <UIFormInput
                  onChange={({target}) => setTargetAudience({...targetAudience, interest: targetAudience.interest.map((el, indexScope) => {

                      if(index === indexScope) {
                        return target.value

                      }

                      return el
                    })})}
                  type="text"
                  className='position-relative'
                  id="title"
                  deleteIcon
                  value={targetAudience?.interest[index] ? targetAudience?.interest[index] : ""}
              />
              <div className='icon-delete' onClick={() => setTargetAudience({...targetAudience, interest: targetAudience.interest.filter((_,indexScope) => indexScope !== index )})}/>
            </Col>
        ))}
      </Row>
      <Row>
        <div className='button-add' onClick={() => setTargetAudience({...targetAudience, interest: [...targetAudience.interest, '']})}>
          +
        </div>
      </Row>
          <Row>
            <span className='title-form'>Потребности:</span>
            {(targetAudience?.requirements || []).map((item, index) => (
                <Col xs={4} className=''>
                  <UIFormInput
                      onChange={({target}) => setTargetAudience({...targetAudience, requirements: targetAudience.requirements.map((el, indexScope) => {

                          if(index === indexScope) {
                            return target.value

                          }

                          return el
                        })})}
                      type="text"
                      className='position-relative'
                      id="title"
                      deleteIcon
                      value={targetAudience?.requirements[index] ? targetAudience?.requirements[index] : ""}
                  />
                  <div className='icon-delete' onClick={() => setTargetAudience({...targetAudience, requirements: targetAudience.requirements.filter((_,indexScope) => indexScope !== index )})}/>
                </Col>
            ))}
          </Row>
          <Row>
            <div className='button-add' onClick={() => setTargetAudience({...targetAudience, requirements: [...targetAudience.requirements, '']})}>
              +
            </div>
          </Row>
          <Row>
            <span className='title-form'>Основные заголовки:</span>
            {(adsContentData?.titles || []).map((item, index) => (
                <Col xs={4} className=''>
                  <UIFormInput
                      onChange={({target}) => setAdsContentData({...adsContentData, titles: adsContentData.titles.map((el, indexScope) => {

                          if(index === indexScope) {
                            return target.value

                          }

                          return el
                        })})}
                      type="text"
                      className='position-relative'
                      id="title"
                      deleteIcon
                      value={adsContentData?.titles[index] ? adsContentData?.titles[index] : ""}
                  />
                  <div className='icon-delete' onClick={() => setAdsContentData({...adsContentData, titles: adsContentData.titles.filter((_,indexScope) => indexScope !== index )})}/>
                </Col>
            ))}
          </Row>
          <Row>
            <div className='button-add' onClick={() => setAdsContentData({...adsContentData, titles: [...adsContentData.titles, '']})}>
              +
            </div>
          </Row>
          <Row>
            <span className='title-form'>Описание:</span>
            {(adsContentData?.descriptions || []).map((item, index) => (
                <Col xs={4} className=''>
                  <UIFormInput
                      onChange={({target}) => setAdsContentData({...adsContentData, descriptions: adsContentData.descriptions.map((el, indexScope) => {

                          if(index === indexScope) {
                            return target.value

                          }

                          return el
                        })})}
                      type="text"
                      className='position-relative'
                      id="title"
                      deleteIcon
                      value={adsContentData?.descriptions[index] ? adsContentData?.descriptions[index] : ""}
                  />
                  <div className='icon-delete' onClick={() => setAdsContentData({...adsContentData, descriptions: adsContentData.descriptions.filter((_,indexScope) => indexScope !== index )})}/>
                </Col>
            ))}
          </Row>
          <Row>
            <div className='button-add' onClick={() => setAdsContentData({...adsContentData, descriptions: [...adsContentData.descriptions, '']})}>
              +
            </div>
          </Row>
        <Row>
          <span className='title-form'>Ключевые слова:</span>
          {(adsContentData?.keywords || []).map((item, index) => (
              <Col xs={4} className=''>
                <UIFormInput
                    onChange={({target}) => setAdsContentData({...adsContentData, keywords: adsContentData.keywords.map((el, indexScope) => {

                        if(index === indexScope) {
                          return target.value

                        }
                        return el
                      })})}
                    type="text"
                    className='position-relative'
                    id="title"
                    deleteIcon
                    value={adsContentData?.keywords[index] ? adsContentData?.keywords[index] : ""}
                />
                <div className='icon-delete' onClick={() => setAdsContentData({...adsContentData, keywords: adsContentData.keywords.filter((_,indexScope) => indexScope !== index )})}/>
              </Col>
          ))}
        </Row>
        <Row>
          <div className='button-add' onClick={() => setAdsContentData({...adsContentData, keywords: [...adsContentData.keywords, '']})}>
            +
          </div>
        </Row>
        <Row>
          <span className='title-form'>Минус-слова:</span>
          {(adsContentData?.stop_words || []).map((item, index) => (
              <Col xs={4} className=''>
                <UIFormInput
                    onChange={({target}) => setAdsContentData({...adsContentData, stop_words: adsContentData.stop_words.map((el, indexScope) => {

                        if(index === indexScope) {
                          return target.value

                        }

                        return el
                      })})}
                    type="text"
                    className='position-relative'
                    id="title"
                    deleteIcon
                    value={adsContentData?.stop_words[index] ? adsContentData?.stop_words[index] : ""}
                />
                <div className='icon-delete' onClick={() => setAdsContentData({...adsContentData, stop_words: adsContentData.stop_words.filter((_,indexScope) => indexScope !== index )})}/>
              </Col>
          ))}
        </Row>
        <Row>
          <div className='button-add' onClick={() => setAdsContentData({...adsContentData, stop_words: [...adsContentData.stop_words, '']})}>
            +
          </div>
        </Row>
      <Row>
        <Col xs={6}>
          <UIFormTextaria
              onChange={handleChangeInput}
              type="text"
              className="input-textaria"
              id="text_prompt"
              label={"Промпт для текса"}
              value={build?.text_prompt ? build?.text_prompt : ""}
          />
        </Col>
        <Col xs={6}>
          <Button
              onClick={handleClickGeneratePromt}
              disabled={isDisabledButtonPrompt()}
              variant="primary"
              style={{width: "210px", height: "38px",marginTop: '32px'}}
          >
            {isLoadingPrompt ? (
                <Spinner animation="border" size="sm" />
            ) : (
                "Сгенерировать промпт"
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <UIFormTextaria
              onChange={handleChangeInput}
              type="text"
              className="input-textaria"
              id="image_prompt"
              label={"Промпт для картинок"}
              value={build?.image_prompt ? build?.image_prompt : ""}
          />
        </Col>

        <Col xs={6}>
          <Button
              onClick={handleClickGenerateImagePrompt}
              disabled={isDisabledButtonPrompt()}
              variant="primary"
              style={{width: "210px", height: "38px",marginTop: '32px'}}
          >
            {isLoadingImagePrompt ? (
                <Spinner animation="border" size="sm" />
            ) : (
                "Сгенерировать промпт"
            )}
          </Button>
        </Col>

      </Row>
      <Row>
        <Col xs={6}>
          <UIFormTextaria
              onChange={handleChangeInput}
              type="text"
              className="input-textaria"
              id="logo_prompt"
              label={"Промпт для лого"}
              value={build?.logo_prompt ? build?.logo_prompt : ""}
          />
        </Col>
        <Col xs={6}>
          <Button
              onClick={handleClickGenerateLogoPrompt}
              disabled={isDisabledButtonPrompt()}
              variant="primary"
              style={{width: "210px", height: "38px",marginTop: '32px'}}
          >
            {isLoadingLogoPrompt ? (
                <Spinner animation="border" size="sm" />
            ) : (
                "Сгенерировать промпт"
            )}
          </Button>
        </Col>
      </Row>
      <Row>
        <Col xs={6}>
          <UIFormTextaria
              onChange={handleChangeInput}
              type="text"
              className="input-textaria"
              id="favicon_prompt"
              label={"Промпт для favicon"}
              value={build?.favicon_prompt ? build?.favicon_prompt : ""}
          />
        </Col>
        <Col xs={6}>
          <Button
              onClick={handleClickGenerateFaviconPrompt}
              disabled={isDisabledButtonPrompt()}
              variant="primary"
              style={{width: "210px", height: "38px",marginTop: '32px'}}
          >
            {isLoadingFaviconPrompt ? (
                <Spinner animation="border" size="sm" />
            ) : (
                "Сгенерировать промпт"
            )}
          </Button>
        </Col>
      </Row>
      <div className="d-flex mt-3 gap-2">
        <div className="ml-1 d-flex gap-2">
          <Button
            onClick={handleClickButton}
            disabled={isDisabledButton()}
            variant="primary"
            style={{ width: "142px", height: "38px" }}
          >
            {isLoading ? (
              <Spinner animation="border" size="sm" />
            ) : (
              "Cгенерировать"
            )}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default GeneratorWhitePage;
